import React, { useState } from "react"
import ToggleTextButton from "src/core/components/ToggleTextButton"
import { useUsersContext } from "../users.context"
import { useUserFollow } from "../users.hooks"
import { IUserResult } from "../users.interfaces"
import UserUnfollowDialog from "./UserUnfollowDialog"

interface UserFollowButtonProps {
  user: IUserResult
}

const UserFollowButton: React.FC<UserFollowButtonProps> = ({ user }) => {
  const { followUser, unfollowUser, isLoading, isFollowing } = useUserFollow(!!user.following)
  const { searchInput, setSearchInput } = useUsersContext()
  const [isUserUnfollowDialogOpen, setIsUserUnfollowDialogOpen] = useState<boolean>(false)

  return (
    <>
      <ToggleTextButton
        isToggled={isFollowing}
        onText="Followed"
        offText={user.followedBy ? "Follow back" : "Follow"}
        hoverAndOnText="Unfollow"
        hoverAndOffText={user.followedBy ? "Follow back" : "Follow"}
        isLoading={isLoading}
        toggleOn={async () => {
          await followUser(user)
          if (searchInput?.length) {
            setSearchInput("")
          }
        }}
        toggleOff={async () => {
          setIsUserUnfollowDialogOpen(true)
        }}
      />
      <UserUnfollowDialog
        title={"Unfollow " + user.name}
        unfollow={async (removeUserResources?: boolean) => {
          await unfollowUser(user, removeUserResources)
          if (searchInput?.length) {
            setSearchInput("")
          }
        }}
        isOpen={isUserUnfollowDialogOpen}
        setIsOpen={setIsUserUnfollowDialogOpen}
      />
    </>
  )
}

export default UserFollowButton
