const ObjectivesListSkeleton = () => {
  return (
    <div className="flex flex-col mt-0.5 animate-pulse">
      {Array(6)
        .fill(true)
        .map((_value, idx) => (
          <div key={idx} className="flex-1 my-1">
            <div className="h-12 bg-gray-300"></div>
          </div>
        ))}
    </div>
  )
}

export default ObjectivesListSkeleton
