import { getAntiCSRFToken } from "@blitzjs/auth"
import { IGetUsersResponse, IUserResult } from "./users.interfaces"

const getUsers = async (query?: string): Promise<IGetUsersResponse> => {
  const antiCSRFToken = getAntiCSRFToken()
  const response: Response = await fetch(
    `/api/users${query ? "?q=" + encodeURIComponent(query) : ""}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "anti-csrf": antiCSRFToken,
      },
    }
  )
  return await response.json()
}

const getByUsername = async (username: string) => {
  const antiCSRFToken = getAntiCSRFToken()
  const response = await fetch(`/api/users/${username}`, {
    credentials: "include",
    headers: {
      "anti-csrf": antiCSRFToken,
    },
  })
  return (await response.json()) as IUserResult
}

const follow = async ({ id, name, username, photoUrl, youtube }: IUserResult) => {
  const antiCSRFToken = getAntiCSRFToken()
  const response = await fetch(`/api/users/follow`, {
    method: "POST",
    credentials: "include",
    headers: {
      "anti-csrf": antiCSRFToken,
    },
    body: JSON.stringify({
      userId: id,
      name,
      username,
      photoUrl,
      youtubeUrl: youtube,
    }),
  })
  return response.status === 200
}

const unfollow = async (userId: number, removeUserResources?: boolean) => {
  const antiCSRFToken = getAntiCSRFToken()
  const response = await fetch(`/api/users/unfollow`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "anti-csrf": antiCSRFToken,
    },
    body: JSON.stringify({
      userId,
      removeUserResources,
    }),
  })
  return response.status === 200
}

const UsersService = {
  getByUsername,
  getUsers,
  follow,
  unfollow,
}

export default UsersService
