import { ComponentProps } from "react"
import { classNames } from "utils"

export interface ITab {
  key: string
  value: string
  count?: number
  icon?: (props: ComponentProps<"svg">) => JSX.Element
}
interface TabsProps {
  tabs: ITab[]
  selected: string
  onSelect: (tab: ITab) => void
  disabled?: boolean
}

export default function Tabs({ tabs, selected, onSelect, disabled }: TabsProps) {
  return (
    <div className="sm:block">
      <nav
        className="relative z-0 flex divide-x divide-gray-200 rounded-lg shadow"
        aria-label="Tabs"
      >
        {tabs.map((tab) => (
          <button
            key={tab.value}
            className={classNames(
              tab.key === selected ? "text-gray-700" : "text-gray-500 hover:text-gray-700",
              "group relative min-w-0 flex-1 overflow-hidden bg-white py-3 px-2 text-sm font-medium text-center hover:bg-gray-50 focus:z-10",
              disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
            )}
            aria-current={tab.key === selected ? "page" : undefined}
            onClick={() => {
              onSelect(tab)
            }}
            disabled={disabled}
          >
            {tab.icon && (
              <tab.icon
                className={classNames(
                  tab.key === selected ? "text-red-500" : "text-gray-400 group-hover:text-gray-500",
                  "inline-block -ml-2 mr-2 h-5 w-5"
                )}
                aria-hidden="true"
              />
            )}
            <span>{tab.value}</span>
            <span
              className={classNames(
                tab.key === selected ? "bg-red-100 text-red-600" : "bg-gray-100 text-gray-700",
                "ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium inline-block"
              )}
            >
              {tab.count || 0}
            </span>
            <span
              aria-hidden="true"
              className={classNames(
                tab.key === selected ? "bg-red-500" : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5"
              )}
            />
          </button>
        ))}
      </nav>
    </div>
  )
}
