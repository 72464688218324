import { useQuery } from "@blitzjs/rpc"
import constate from "constate"
import { Invitation } from "db"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import useDebounce from "src/core/hooks/useDebounce"
import { ITab } from "../core/components/Tabs"
import getUsers from "./queries/getUsers"
import { USER_DISPLAY_LIMIT, UserRelationship } from "./users.constants"
import { IUserResult } from "./users.interfaces"

const useUsers = () => {
  const [users, setUsers] = useState<IUserResult[]>([])
  const [invitations, setInvitations] = useState<Partial<Invitation>[]>([])

  const [searchInput, setSearchInput] = useState<string>("")
  const debouncedInput = useDebounce(searchInput, 500)

  const [usersFilteredByLocation, setUsersFilteredByLocation] = useState<IUserResult[]>([])

  const [userRelationship, setUserRelationship] = useState(UserRelationship.FOLLOWING)
  const [userRelationships, setUserRelationships] = useState<ITab[]>([
    {
      key: UserRelationship.FOLLOWING,
      value: "Following",
    },
    {
      key: UserRelationship.FOLLOWERS,
      value: "Followers",
    },
    // {
    //   key: UserRelationship.SUGGESTED,
    //   value: "Suggested",
    // },
    {
      key: UserRelationship.INVITATIONS,
      value: "Invitations",
    },
  ])

  const [getFollowingCount, setGetFollowingCount] = useState<number>(0)
  const [getFollowersCount, setGetFollowersCount] = useState<number>(0)
  const [searchCount, setSearchCount] = useState<number>(0)
  const [skip, setSkip] = useState<number>(0)

  const [result, { refetch, isFetching: isLoading }] = useQuery(
    getUsers,
    {
      q: debouncedInput,
      r: userRelationship,
      skip,
    },
    {
      suspense: false,
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )

  useEffect(() => {
    setSkip(0)
  }, [userRelationship, debouncedInput])

  useEffect(() => {
    if (result) {
      if (skip) setUsers((current) => [...current, ...result.users])
      else setUsers(result.users)
      setInvitations(result.invitations || [])

      if (result.followingCount) {
        setGetFollowingCount(result.followingCount)
      }
      if (result.followedByCount) {
        setGetFollowersCount(result.followedByCount)
      }
      if (debouncedInput?.length && !skip) {
        setSearchCount(result.count || 0)
      }
      if (result.followingCount || result.followedByCount) {
        setUserRelationships(
          userRelationships.map((tab) => {
            if (tab.key === UserRelationship.FOLLOWING) {
              return {
                ...tab,
                count: result.followingCount,
              }
            }
            if (tab.key === UserRelationship.FOLLOWERS) {
              return {
                ...tab,
                count: result.followedByCount,
              }
            }
            if (tab.key === UserRelationship.INVITATIONS) {
              return {
                ...tab,
                count: result.invitationsCount,
              }
            }
            return tab
          })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  // useEffect(() => {
  //   if (userId) {
  //     if (debouncedInput) {
  //       UsersService.getUsers(debouncedInput)
  //         .then((response: IGetUsersResponse) => {
  //           setUsers(response.users)
  //           setInvitations(response.invitations || [])
  //           setIsLoading(false)
  //         })
  //         .catch((_err) => {})
  //     }
  //   }
  // }, [debouncedInput, userId])

  const [locationFilter, setLocationFilter] = useState<string>()

  useEffect(() => {
    setUsersFilteredByLocation(
      locationFilter?.length ? users.filter((user) => user.location?.id === locationFilter) : users
    )
  }, [locationFilter, users])

  const router = useRouter()

  // Update URL from query params
  useEffect(() => {
    router
      .push(
        {
          pathname: `/users`,
          query: {
            ...(debouncedInput ? { q: debouncedInput } : {}),
          },
        },
        undefined,
        { shallow: true }
      )
      .catch(() => {})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput])

  useEffect(() => {
    const url = new URL(window.location as any)
    if (url.searchParams.get("q")) {
      setSearchInput(url.searchParams.get("q")!)
    }
  }, [])

  const paginate = () => {
    setSkip((current) => current + USER_DISPLAY_LIMIT)
  }

  const canPaginate = () => {
    if (debouncedInput?.length) {
      return searchCount > users.length
    }
    if (userRelationship === UserRelationship.FOLLOWING) {
      return getFollowingCount > users.length
    }
    if (userRelationship === UserRelationship.FOLLOWERS) {
      return getFollowersCount > users.length
    }
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  return {
    users,
    invitations,
    setUsers,
    isLoading,

    searchInput,
    debouncedInput,
    setSearchInput,

    locationFilter,
    setLocationFilter,
    usersFilteredByLocation,

    userRelationship,
    userRelationships,
    setUserRelationship,
    setUserRelationships,
    paginate,
    canPaginate,
    skip,
  }
}

export const [UsersProvider, useUsersContext] = constate(useUsers)
