import { useSession } from "@blitzjs/auth"
import Link from "next/link"
import { useCallback } from "react"
import { FaInstagram, FaYoutube } from "react-icons/fa"
import { classNames, parseBoolean } from "utils"
import Avatar from "../../core/components/Avatar"
import { IUserResult } from "../users.interfaces"
import UserFollowButton from "./UserFollowButton"

interface Props {
  user: IUserResult
  noFollow?: boolean
  count?: number
}

const UserItem = ({ user, noFollow, count }: Props) => {
  const { id, name, username, photoUrl, followedBy, location, youtube, instagram } = user || {}
  const { userId } = useSession()

  const canShowName = useCallback(() => {
    return !parseBoolean(process.env.USER_SEARCH_PRIVACY_ENABLED) || followedBy || userId === id
  }, [followedBy, id, userId])

  return (
    <Link href={`/users/${username}`} passHref>
      <div
        key={id}
        className="my-1 flex w-full cursor-pointer justify-between border-none bg-white p-2 text-gray-500 shadow-sm hover:bg-gray-200"
      >
        <div className="flex items-center justify-center px-1">
          <Avatar name={name} src={photoUrl} className="h-8 w-8" />
          <div className="flex flex-col space-x-0 px-3 text-left">
            {canShowName() && (
              <div className="text-md text-left font-semibold text-gray-700">{name}</div>
            )}
            <div className="flex items-left justify-left">
              {youtube && (
                // <a
                //   href={youtube}
                //   target="_blank"
                //   rel="noopener noreferrer"
                //   className="flex items-center justify-center pr-1"
                //   onClick={(e) => e.stopPropagation()}
                // >
                <FaYoutube className="text-red-500 mr-1" size={"1.4rem"} />
                // </a>
              )}
              {instagram && (
                // <a
                //   href={instagram
                //   target="_blank"
                //   rel="noopener noreferrer"
                //   className="flex items-center justify-center pr-1"
                //   onClick={(e) => e.stopPropagation()}
                // >
                <FaInstagram className="text-red-500 mr-1" size={"1.4rem"} />
                // </a>
              )}

              <div className={classNames("text-left", canShowName() ? "text-sm" : "text-lg")}>
                @{username}
              </div>
            </div>
            {/* {location && <div className="text-sm text-left">{location.name}</div>} */}
          </div>
        </div>
        <div className="flex">
          {count && (
            <div className="flex items-center justify-center px-1">
              <span className="text-gray-500 text-sm">
                {count} resource{count === 1 ? "" : "s"} loading...
              </span>
            </div>
          )}

          {userId !== id && !noFollow && (
            <div className="inline-flex space-x-2 space-y-2">
              <UserFollowButton user={user} />
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

export default UserItem
