import { useSession } from "@blitzjs/auth"
import { useEffect, useState } from "react"
import { IUserResult } from "./users.interfaces"
import UsersService from "./users.service"

export const useUserFollow = (following?: boolean) => {
  const session = useSession({ suspense: false })

  const [isFollowing, setIsFollowing] = useState<boolean>(!!following)

  useEffect(() => {
    setIsFollowing(!!following)
  }, [following])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const followUser = async (user: IUserResult) => {
    if (session.userId && session.userId !== user.id) {
      setIsLoading(true)
      if (await UsersService.follow(user)) {
        setIsFollowing(true)
      }
      setIsLoading(false)
    }
  }

  const unfollowUser = async (user: IUserResult, removeUserResources?: boolean) => {
    if (session.userId && session.userId !== user.id) {
      setIsLoading(true)
      if (await UsersService.unfollow(user.id, removeUserResources)) {
        setIsFollowing(false)
      }
      setIsFollowing(false)
      setIsLoading(false)
    }
  }

  return { followUser, unfollowUser, isFollowing, setIsFollowing, isLoading }
}

export const useUserProfile = (username?: string) => {
  const [user, setUser] = useState<IUserResult>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (username) {
      setIsLoading(true)
      UsersService.getByUsername(username)
        .then((result) => {
          setUser(result)
          setIsLoading(false)
        })
        .catch((_err) => {})
    }
  }, [username])

  return { user, isLoading }
}
